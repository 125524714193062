const daysOfTheWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const daysInWeek = {0: "mon", 1: "tue", 2: "wed", 3: "thu", 4: "fri", 5: "sat", 6: "sun"};
const environments = {all: "All", review: "Review", stage: "Stage", production: "Production", sandbox: "Sandbox"}
var schedule = {};

hidePopup = () => {
  $(".dataCellClicker").removeClass("selected");
  $("#dataPopup").addClass("hideview");
}

showPopup = (x,y) => {
  obj = document.getElementById("dataPopup");
  obj.classList.remove("hideview");

  popup = $(".dataPopup");
  popup.css({
    left: x,
    top: y
  })

}

movePopup = (x,y) => {
  obj = document.getElementById("dataPopup");
  obj.classList.remove("hideview");

  popup = $(".dataPopup");
  popup.css({
    left: x,
    top: y
  })

}

generateHeader = function(targetID){
  obj = targetID;
  targetID = obj.attr("id");
  dynoId = obj.attr("data-dyno-id");
  html = `<div class="heading-cont"><button class="select-all" data-dyno-id="${dynoId}">Select All</button></div>`;
  for(hour = 0 ; hour <= 23 ; hour++){
    hour = hour <= 9 ? '0'+hour : hour;
    html += `<div class="d-flex justify-content-center align-items-center flex-column currHr" data-current="${hour}"><span>${hour}h</span><span class="offset"></span></div>`;
  }

  finalHTML = `<div class="grid-heading d-flex" id="${targetID}Head">
                ${html}
              </div>`;

  obj.html(finalHTML);
}

generateCells = function(targetID, dynoID){
  $.ajax({
    url: '/scheduler/v1/scheduler_page/get_schedule_for_dyno',
    method: 'get',
    async: false,
    data: {
      dyno_id: dynoID
    },
    success: function (data) {
      schedule = data;
      obj = targetID;
      targetID = obj.attr("id");
      html = ``;
      for(day = 0 ; day <= 6 ; day++){
        html += `<div class="d-flex flex-row"><div class="heading-cont d-flex justify-content-center align-items-center">${ daysOfTheWeek[day] }</div>`;
        for(hour = 0 ; hour <= 23 ; hour++){
          schedule[daysInWeek[day]] = schedule[daysInWeek[day]] || {};
          html += `<div class="d-flex align-items-stretch justify-content-center align-items-center align-items-stretch dataCellClicker dataCellClicker_${dynoID} dataCell dataCell_${dynoID}" data-day="${day}" data-hour="${hour}" data-dyno-count="${schedule[daysInWeek[day]][hour]}">
                    <div class="dataCell-content flex-fill">
                      <div class="data processDisplay noclick">`;
                      if (Number.isInteger(schedule[daysInWeek[day]][hour])) {
          html +=    `<span>${schedule[daysInWeek[day]][hour]}</span>`;
                      }
          html +=     `</div>
                      <div class="paused-timer noclick">
                        <div class="paused-timer-bar noclick"></div>
                      </div>
                    </div>
                  </div>`;
        }
        html += `</div>`;
      }
      html += ``;

      finalHTML = `<div class="grid-cells d-flex selectables flex-column" id="${targetID}Cells">
                  ${html}
                  </div>`;
      obj.append(finalHTML);
    },
    error: function() { 
      swal(
        'Oops!',
        'Something went wrong.',
        'error'
      )
    }
  });      
}


showLastSchedulerActivity = function(dynoID) {
  $.ajax({
    url: '/scheduler/v1/scheduler_page/get_latest_schedule_date_for_dyno',
    method: 'get',
    async: false,
    data: {
      dyno_id: dynoID
    },
    success: function (data) {
      var date = new Date(data.latest_sync_date).toUTCString('en-US', {year: 'numeric', month: 'short', day: '2-digit', weekday:"short", hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'});
      if(data.latest_sync_date == null){
        $(".lastSyncDate_"+dynoID).text("Never");
      }
      else{
        $(".lastSyncDate_"+dynoID).text(date).attr("data-last-sync", date);
      }
    },
    error: function() { 
      swal(
        'Oops!',
        'Something went wrong.',
        'error'
      )
    }
  });
};

showConvertedTime = function(dynoID) {
  $.ajax({
    url: '/scheduler/v1/scheduler_page/get_timezone',
    method: 'get',
    async: false,
    success: function (data) {
      $(".timezoneSelection").val(data.user_timezone);
      var selected_option = $(".timezoneSelection option:selected");
      convertSyncTime(selected_option, dynoID);
      convertScheduleTime(data.user_timezone);
    },
    error: function() { 
      swal(
        'Oops!',
        'Something went wrong.',
        'error'
      )
    }
  });
}

initUI = () => {
  var i = 0;
  updateTime = function(){
    var date = new Date();
    document.getElementById("dateDisplay").innerHTML = date;
  }

  $(".tab-pane").each(function(){
    $(".tab_pane_"+i).show();
    $(".dynoLastSync_"+i).show();
    $(".dynoSchedulerActivity_"+i).show();
    $(".dynoconvertedTime_"+i).show();
    var obj = $(this);
    var objID = obj.attr("id");
    var dynoID = $(this).attr('data-dyno-id');
    generateHeader(obj);
    generateCells(obj, dynoID);
    showLastSchedulerActivity(dynoID);
    showConvertedTime(dynoID);
    if(i > 0) {
      $(".tab_pane_"+i).hide();
      $(".dynoLastSync_"+i).hide();
      $(".dynoSchedulerActivity_"+i).hide();
      $(".dynoconvertedTime_"+i).hide();
    }
    i = i + 1;
  })
}

changePopupView = () => {
  selectedUI    = $(".ui-selected");
  hasValue = false;
  isPaused = false;

  selectedUI.each(function(){
    objIsPaused = $(this).hasClass("paused");
    container = $(this).children().find(".processDisplay").html().length;
    if(container > 0){
      hasValue = true;
    }
    if(objIsPaused){
      isPaused = true;
    }
  })

  if(hasValue){
    $(".pause-settings").removeClass("hidden");

    if(isPaused){
      $(".selections").addClass("hidden");
      $(".resume-settings").removeClass("hidden");
      $(".pause-settings").addClass("hidden");
    }else{
      $(".selections").removeClass("hidden");
      $(".resume-settings").addClass("hidden");
      $(".pause-settings").removeClass("hidden");
    }

  }else{
    $(".selections").removeClass("hidden");
    $(".resume-settings").addClass("hidden");
    $(".pause-settings").addClass("hidden");
  }
}

multipleSelection = () => {

  selectedUI    = $(".tab-pane.active .ui-selected");
  if(selectedUI.length > 0){
    firstElement  = selectedUI.first();
    lastElement   = selectedUI.last();

    first_x = firstElement.position().left;
    first_y = firstElement.position().top;

    last_x = lastElement.position().left;
    last_y = lastElement.position().top;

    x_center = ((first_x + last_x) / 2) - 95;
    y_center = ((first_y + last_y) / 2) + 35;

    changePopupView();
    showPopup(x_center, y_center);
    movePopup(x_center, y_center);
  }

}

$(document).on("click", ".nav-item button", function(){
  $(".dataCellClicker").removeClass("ui-selected");
  hidePopup();
});

$(document).on("click", ".dataCellClicker", function(){
  obj = $(this);

  if(obj.hasClass("ui-selected")){
    $(".dataCellClicker").removeClass("ui-selected");
    obj.removeClass("ui-selected");
    hidePopup();
  }else{
    $(".dataCellClicker").removeClass("ui-selected");
    obj.addClass("ui-selected");
    multipleSelection();
    showPopup();
  }
});

// Set the dyno count for selected hour for selected days in database
$(document).on("click", ".dynoCountContainer", function(){
  selectedCells = $(".dataCellClicker.ui-selected");
  dynoCount = parseInt($(".dynoCountContainer > input.dynoCount:checked").val());
  setTimeout(function(){
    // Looping through all highlighted cells to set the dyno count for a praticular hour at that day
    selectedCells.each(function(){
      cell = selectedCells.children().find(".processDisplay");
      // update cell text with dyno count
      cellDisplay = `<span style="color: #878d8a">${dynoCount}</span>`;
      cell.html(cellDisplay);
      $(this).attr('data-dyno-count', dynoCount);
      $(this).removeClass("ui-selected");
    })
  }, 1000);
})

// Undo the temporary dyno count changes for each hour for all days
$(document).on("click", ".btnCancel", function(){
  dynoId = $(this).attr('data-dyno-id');
  index = $(this).attr('data-index');
  obj = $(".tab_pane_"+index);
  hidePopup();
  generateHeader(obj);
  generateCells(obj, dynoId);
  showConvertedTime(dynoId);
  initializeSelectable();
})

// Storing the dyno count for each hour for all days in database
$(document).on("click", ".btnSave", function(){
  allCells = $(".dataCellClicker");
  var dynoSchedule = {};
  appId = $(this).attr('data-app-id');
  appName = $(this).attr('data-app-name');
  dynoId = $(this).attr('data-dyno-id');
  dynoType = $(this).attr('data-dyno-type');
  index = $(this).attr('data-index');
  obj = $(".tab_pane_"+index);
  requiredCells = $(".dataCellClicker_"+dynoId);
  // Looping through all cells to get the dyno count for each hour for all days
  requiredCells.each(function(){
    selectedDay = daysInWeek[$(this).attr('data-day')];
    selectedHour = $(this).attr('data-hour');
    dynoCount = parseInt($(this).attr("data-dyno-count"));
    dynoSchedule[selectedDay] = dynoSchedule[selectedDay] || {};
    if (Number.isInteger(dynoCount)) {
      dynoSchedule[selectedDay][selectedHour] = dynoCount;
    }
  })
  schedule = JSON.stringify(dynoSchedule);
  hidePopup();
  $.ajax({
    url: '/scheduler/v1/scheduler_page',
    method: 'post',
    data: {
      dyno_id: dynoId,
      app_id: appId,
      dyno_type: dynoType,
      app_name: appName,
      schedule: schedule
    },
    success: function (output, status, xhr) {
      var flash = xhr.getResponseHeader('x-flash');
      swal.fire(flash);
      generateHeader(obj);
      generateCells(obj, dynoId);
      showConvertedTime(dynoId);
      initializeSelectable();
    },
    error: function() { 
      swal(
        'Oops!',
        'Something went wrong.',
        'error'
      )
    }
  })
})

selectAllCell = function(dynoId){
  $(".dataCell_"+dynoId).addClass("ui-selected");
  showPopup();
}

$(document).on("click", ".select-all", function(){
  var dynoId = $(this).attr("data-dyno-id");
  selectAllCell(dynoId);
})

$(document).on("click", ".appDyno", function(){
  var selected_option = $(".timezoneSelection option:selected");
  var tz = $(".timezoneSelection").val();
  $('.dynoSchedule').hide();
  $('.lastSyncDate').hide();
  $('.btnSchedulerActivity').hide();
  $('.convertedTime').hide();
  var dynoType = $(this).attr("data-dyno-type");
  var dynoId = $(this).attr("data-dyno-id");
  var index = $(this).attr("data-index");
  $('.dynoSchedule_'+dynoId).show();
  $('.lastSyncDate_'+dynoId).show();
  $('.btnSchedulerActivity_'+dynoId).show();
  $('.convertedTime_'+dynoId).show();
  $('.btnSave').attr('data-dyno-id', dynoId);
  $('.btnSave').attr('data-dyno-type', dynoType);
  $('.btnSave').attr('data-index', index);
  $('.btnCancel').attr('data-dyno-id', dynoId);
  $('.btnCancel').attr('data-index', index);
  $('.timezoneSelection').attr('data-dyno-id', dynoId);
  convertSyncTime(selected_option, dynoId);
  convertScheduleTime(tz);
})

$(document).on("click", ".addNewApp", function(){
  var appEnv = $("#appEnv").val();
  var appId = $("#appName").val();
  var appName = $(".app-name_"+appId).attr("data-name");
  $.ajax({
    url: '/scheduler/v1/home_page/create_scheduled_heroku_app',
    method: 'post',
    data: {
      app_env: appEnv,
      app_id: appId,
      app_name: appName
    },
    success: function (output, status, xhr) {
      var flash = xhr.getResponseHeader('x-flash');
      swal.fire(flash);
    },
    error: function() { 
      swal(
        'Oops!',
        'Something went wrong.',
        'error'
      )
    }
  })
})

$(document).on("click", ".btnAddNew", function(){
  $.ajax({
    url: '/scheduler/v1/home_page/add_new_scheduled_heroku_app',
    method: 'get',
  })
})


$(document).on("click", ".btnClose", function(){
  $("#createNewApp").hide();
  $('.modal-backdrop').hide();
})

convertSyncTime = function(dateTimeObj, dynoId){
  let syncTime = $(".lastSyncDate_"+dynoId).attr("data-last-sync");
  const timezone = dateTimeObj.attr("data-timezone");
  const offset = dateTimeObj.attr("data-offset");
  if(offset == 0){
    $(".convertedTime_"+dynoId).html("");
  }else{
    var syncDate = new Date(syncTime);
    let newTimeZoneObj = syncDate.toLocaleString('en-US', {
      timeZone: timezone
    })
    syncDate = newTimeZoneObj;
    $(".convertedTime_"+dynoId).html(syncDate);
  }

}

$(document).on("change", ".timezoneSelection", function(){
  var tz = $(".timezoneSelection").val();
  var selected_option = $(".timezoneSelection option:selected");
  var dynoId = $(this).attr("data-dyno-id");

  $.ajax({
    url: '/scheduler/v1/scheduler_page/set_timezone',
    method: 'post',
    data: {
      timezone: tz,
    },
    success: function () {
      convertSyncTime(selected_option, dynoId);
      convertScheduleTime(tz);
    },
    error: function() { 
      swal(
        'Oops!',
        'Something went wrong.',
        'error'
      )
    }
  })
})

convertScheduleTime = function(tz){
  $(".currHr").each(function(){
    var obj = $(this);

    if(tz != 0){
      var val = obj.attr("data-current");
      var tm = tz.split(":");
      hr = parseInt(tm[0]);
      mn = parseInt(tm[1]) == 0 ? "00" : tm[1];
      var newTime = parseInt(val)+hr;
      newTime = newTime < 24 ? newTime : newTime - 24;
      newTime = newTime <= 9  ? `0${newTime}` : newTime;
      var display = `${newTime}:${mn}`;
      
      obj.addClass("converted");
      obj.children("span.offset").html(display);
    }else{
      obj.removeClass("converted");
      obj.children("span.offset").html("");
    }
  })
}

$(document).on("click", ".envFilter", function(){
  selectedAppEnv = $(this).attr("data-environment");
  $(".envDisplay").text(environments[selectedAppEnv]);
  appsList = $("#scheduled_heroku_apps").val();
  var apps = JSON.parse(appsList);
  $("#scheduled_heroku_apps_details").empty();
  apps.forEach(function (app) {
    const [appId, appName, appEnv] = app;
    if(appName.match(selectedAppEnv) || selectedAppEnv == 'all') {
      var scheduled_heroku_apps_details = `<a href="/scheduler/v1/scheduler_page/${appId}" class="list-group-item list-group-item-action d-flex justify-content-between">${appName}
                          <span class="badge align-items-center d-flex justify-content-center text-bg-primary rounded-pill">${environments[appEnv]}</span>
                         </a>`
    }
    $("#scheduled_heroku_apps_details").append(scheduled_heroku_apps_details);
  });
})

$(document).on("click", ".app-search", function(){
  requiredApp = $("#search-for-apps").val();
  appsList = $("#scheduled_heroku_apps").val();
  $("#scheduled_heroku_apps_details").empty();
  apps.forEach(function (app) {
    const [appId, appName, appEnv] = app;
    if(requiredApp.match(appName) || requiredApp.length === 0) {
      var scheduled_heroku_apps_details = `<a href="/scheduler/v1/scheduler_page/${appId}" class="list-group-item list-group-item-action d-flex justify-content-between">${appName}
                          <span class="badge align-items-center d-flex justify-content-center text-bg-primary rounded-pill">${environments[appEnv]}</span>
                         </a>`
    }
    $("#scheduled_heroku_apps_details").append(scheduled_heroku_apps_details);
  });
})

$(document).on("click", ".btnPause", function(){
  var appId = $(this).attr('data-app-id');
  var appName = $(this).attr('data-app-name');
  var pausedTime = $("#hourSelection").val();
  $.ajax({
    url: '/scheduler/v1/scheduler_page/pause_scheduling',
    method: 'put',
    data: {
      app_id: appId,
      app_name: appName,
      paused_time: pausedTime
    },
    success: function (data, status, xhr) {
      var flash = data.message;
      swal.fire(flash);
      $('.btnPause').hide();
      $('.hourSelection').hide();
      $('.btnResume').show();
      $('.schedule_status').show();
      showTimer(data.scheduler_unpause_at);
    },
    error: function() { 
      swal(
        'Oops!',
        'Something went wrong.',
        'error'
      )
    }
  })
})

showTimer = (scheduler_unpause_at) => {
  let timerInterval;
  const unpauseAt = new Date(scheduler_unpause_at);
  const timerElement = document.getElementById('timer');
  updateTimer = function() {
    const now = new Date();
    const timeDifference = unpauseAt - now;

    if (timeDifference > 0) {
      // Calculate hours, minutes, and seconds
      let seconds = Math.floor(timeDifference / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);

      seconds = seconds % 60;
      minutes = minutes % 60;

      // Format the time string
      const timeString = `${hours}h ${minutes}m ${seconds}s`;

      // Update the timer element
      timerElement.textContent = `Schedule Paused. Time remaining: ${timeString}`;
    } else {
      // If the unpause time has passed
      clearInterval(timerInterval); // Stop the timer
    }
  }
  // Update the timer every second
  timerInterval = setInterval(updateTimer, 1000);
}


$(document).on("click", ".btnResume", function(){
  var appId = $(this).attr('data-app-id');
  var appName = $(this).attr('data-app-name');
  $.ajax({
    url: '/scheduler/v1/scheduler_page/unpause_scheduling',
    method: 'put',
    data: {
      app_id: appId,
      app_name: appName
    },
    success: function (data, status, xhr) {
      var flash = data.message;
      swal.fire(flash);
      $('.hourSelection').show();
      $('.btnPause').show();
      $('.btnResume').hide();
      $('.schedule_status').hide();
      showTimer(data.scheduler_unpause_at);
    },
    error: function() { 
      swal(
        'Oops!',
        'Something went wrong.',
        'error'
      )
    }
  })
})